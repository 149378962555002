import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { getSrc } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/seo';
import Section from '../components/Section';
import CallToAction from '../components/CallToAction';
import Poster from '../components/Poster';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  main: {},
  callToAction: {
    minHeight: '25vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function TellSpin(props) {
  const classes = useStyles();
  const { image, schedule, reminders, overrides, recurrence } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        image: file(name: { eq: "calendar-full" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
        schedule: file(name: { eq: "calendar-full" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        overrides: file(name: { eq: "slash-2" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        reminders: file(name: { eq: "reminders" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        recurrence: file(name: { eq: "recurrence" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `,
  );

  return (
    <Layout>
      <Seo
        title="Collaborative habit tracking tool for Slack."
        description="Build momentum by tracking atomic habits with your team. Meet people by joining in easily. Set reminders to get started and build consistency by tracking progress. Inspire each other by sharing wins."
        imageSource={getSrc(image)}
        keywords={['Reminders', 'Shift schedules']}
      />
      <Container maxWidth="lg" component="main" className={classes.main}>
        <Section>
          <Poster
            title="Collaborative atomic habits"
            description="Choose a frequency of daily, weekly, two weeks, monthly, or custom. DO a Thing shows what users are doing what across the entire company"
            img={schedule}
            alt="Collaborative habit tracker for Slack teams"
          />
        </Section>
        <Section inverse>
          <Poster
            title="Get started reminders"
            description="Communicate key check-in's for your things with direct or channel messages"
            img={reminders}
            inverse
            alt="Atomic habit reminders for Slack"
          />
        </Section>
        <Section>
          <Poster
            title="Record with Slash commands"
            description="Mark things as complete quickly with-in Slack. A message is sent to a momentum channel to celebrate your progress"
            img={overrides}
            alt="Slash command complete a thing"
          />
        </Section>
        <Section inverse>
          <Poster
            title="Custom habit intervals"
            description="Similar to how you create recurring events in Google Calendar. Select the rotation that fits your teams needs"
            inverse
            img={recurrence}
            alt="Selecting users"
          />
        </Section>
      </Container>
      <CallToAction
        funnel="features"
        action="Start your free trial! No credit card required"
        className={classes.callToAction}
      />
    </Layout>
  );
}
