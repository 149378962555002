import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SlackButton from './SlackButton';

const useStyles = makeStyles((theme) => ({
  callToAction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    marginTop: 0,
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  actionText: {
    marginBottom: theme.spacing(2),
  },
}));

export default function CallToAction({
  className,
  action,
  left,
  text,
  funnel,
}) {
  const classes = useStyles();

  return (
    <div className={className}>
      <div className={left ? classes.left : classes.callToAction}>
        {action && (
          <Typography className={classes.actionText} variant="h4">
            {action}
          </Typography>
        )}
        {text && text()}

        <SlackButton funnel={funnel} />
      </div>
    </div>
  );
}
