import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    order: 1,
    flexBasis: '49%',
  },
  imageInverse: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 0,
    },
    flexBasis: '49%',
  },
  title: {
    textAlign: 'left',
    marginBottom: '1rem',
  },
  description: {
    marginBottom: '1rem',
  },
  image: {
    borderRadius: 4,
    boxShadow: 'rgba(0, 0, 0, 0.4) 10px 10px 20px',
  },
}));

export default function Poster({ title, description, src, inverse, img, alt }) {
  const classes = useStyles();
  return (
    <>
      <div
        className={classNames(
          inverse ? classes.imageInverse : classes.imageContainer,
        )}
      >
        <GatsbyImage
          alt={alt}
          className="shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 "
          layout="fullWidth"
          image={img.childImageSharp.gatsbyImageData}
        />
      </div>
      <div>
        <Typography className={classes.title} variant="h1">
          {title}
        </Typography>
        <Typography
          className={classes.description}
          variant="body1"
          component="p"
        >
          {description}
        </Typography>
      </div>
    </>
  );
}
